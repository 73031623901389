import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Catalog from '../views/Catalog.vue'
import Reviews from '../views/Reviews.vue'
import Orders from '../views/Orders.vue'
import DataPump from '../views/DataPump.vue'
import Manage from '../views/Manage.vue'
import CatalogDetails from '../views/CatalogDetails.vue'
import OrderDetails from '../views/OrderDetails.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog

  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders

  },
  {
    path: '/datapump',
    name: 'DataPump',
    component: DataPump

  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage

  },
  {
    path: '/catalog/:id',
    name: 'CatalogDetails',
    component: CatalogDetails,
    props: true
  },
  {
    path: '/orders/:id',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true
  },
  {
    path: '/login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  const publicPages = ['/','/login', '/register', '/about'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});


export default router
